import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import logo from "./favicon.svg";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();

export default function SignIn() {
  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login(data.get("email"), data.get("password"));
  };

  const login = async (email, password) => {
    setLoading(true);
    const body = JSON.stringify({
      email: email.toLocaleLowerCase(),
      password: password,
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/v1/auth/signin`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": body.length,
          },
          body: body,
        }
      );
      if (response.status === 200) {
        const token = await response.json();
        if (token.token) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL;
          setLoading(false);
        } else {
          throw new Error("invalid server response");
        }
      } else if (response.status === 401) {
        setLoading(false);
        setError(true);
        setHelperText("Incorrect Username or Password");
      } else {
        throw new Error("server error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "start",
          paddingTop: "20vh",
        }}
      >
        <CssBaseline />
        <img src={logo} />
        <Typography
          component="h1"
          variant="h4"
          style={{ fontWeight: "bold", marginTop: "8px" }}
        >
          Sign in to SmartyrDXP
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={error}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            error={error}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {helperText}
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size="26px" /> : "Sign In"}
          </Button>
          {/*             <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
